import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.config.productionTip = false

Vue.use(VueYandexMetrika, {
  id: 62317804,
  env: process.env.NODE_ENV
})

new Vue({
  Router,
  store,
  vuetify,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
