<template>
  <cookie-law buttonText="Понятно" >
    <div slot="message">
      На сайте используются cookie, без них в Интернете ничего не работает!
      <Soglashenie/>
    </div>
  </cookie-law>
</template>
 
<script>
import CookieLaw from "vue-cookie-law";
import Soglashenie from "@/components/Soglashenie";

export default {
  components: { CookieLaw, Soglashenie },
};
</script>
<style>
.Cookie {
  background: #eceff1;
}
.Cookie__button {
  background-color: #1a237e !important;
}
</style>