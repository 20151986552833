<template>
  <v-form ref="form" v-model="valid" :lazy-validation="lazy" @submit.prevent="onSubmit" id="myForm">
    <v-text-field
      dense
      background-color="white"
      v-model="name"
      :rules="nameRules"
      label="Ваше имя"
      required
      outlined
      clearable
      hint="Например Иван Иванов"
      name="name"
      prepend-inner-icon="mdi-account-box"
    ></v-text-field>

    <v-text-field
      dense
      background-color="white"
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
      outlined
      hint="Например mail@mail.ru"
      clearable
      name="email"
      prepend-inner-icon="mdi-email"
    ></v-text-field>

    <v-text-field
      dense
      background-color="white"
      v-model="phone"
      :rules="phoneRules"
      label="Телефон"
      :counter="12"
      required
      outlined
      clearable
      hint="Например +79035122357"
      name="phone"
      type="tel"
      prepend-inner-icon="mdi-phone"
      placeholder
      v-mask="'+7##########'"
    ></v-text-field>

    <v-select
      dense
      background-color="white"
      :items="courses"
      label="Меня интересует"
      outlined
      hint="Выберите интересующий вас курс"
      name="course"
      prepend-inner-icon="mdi-help"
      v-model="course"
    ></v-select>

    <v-textarea
      dense
      background-color="white"
      outlined
      name="message"
      label="Напишите ваше сообщение"
      prepend-inner-icon="mdi-message"
      v-model="message"
    ></v-textarea>

    <v-checkbox
      dense
      background-color="transparent"
      class="mt-0"
      v-model="checkbox"
      :rules="[v => !!v || 'Вы должны согласиться, чтобы продолжить']"
      label="Соглашаюсь с Политикой конфиденциальности"
      required
    ></v-checkbox>

    <v-btn
      dense
      class="white--text"
      :disabled="!valid"
      color="red"
      x-large
      block
      type="submit"
    >Отправить</v-btn>
  </v-form>
</template>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  data: () => ({
    courses: ["Пробное погружение", "Курс Open Water Diver"],
    valid: true,
    name: "",
    nameRules: [
      v => !!v || "Вы не заполнили имя!",
      v => (v && v.length >= 3) || "Минимум 3 символа"
    ],
    email: "",
    emailRules: [
      v => !!v || "Вы не заполнили адрес почты",
      v => /.+@.+\..+/.test(v) || "E-mail введен неправильно!"
    ],
    phone: "",
    phoneRules: [
      v => !!v || "Вы не заполнили номер!",
      v => (v && v.length >= 12) || "Номер содержит 11 цифр!"
    ],
    course: "",
    message: "",
    checkbox: false,
    lazy: false
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onSubmit() {
      let myForm = document.getElementById("myForm");
      var bodyFormData = new FormData(myForm);

      bodyFormData.append("sitename", "TryDiving");
      bodyFormData.append("fromto", "info@snsipro.ru");

      axios({
        method: "post",
        url: "https://snsipro.ru/api/sendmail",
        data: bodyFormData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          if (response.status === 200) {
            this.$refs.form.reset();
            this.$store.dispatch("snackmessages/snack_on");

            setTimeout(() => {
              this.$store.dispatch("snackmessages/snack_off");
            }, 3000);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
