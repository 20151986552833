<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" sm="8">
      <v-card elevation="0">
        <v-container fluid>
          <v-row>
            <v-col v-for="n in 9" :key="n" class="d-flex child-flex" cols="4">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card flat tile class="d-flex" @click="showphoto=!showphoto">
                    <v-img
                      :src="`img/dsd/${n}.webp`"
                      :lazy-src="`img/dsd/${n}.webp`"
                     
                      class="grey lighten-2"
                    ></v-img>

                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute color="#036358">
                        <v-icon class="display-2" color="white">mdi-magnify-plus-outline</v-icon>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>

    <v-dialog color="white" max-width="640px"  v-model="showphoto">
       <v-card>
       <v-responsive :aspect-ratio="4/3">
       <v-card-text class="pb-0">
        <v-carousel
          class 
          cycle 
          hide-delimiter-background 
          show-arrows-on-hover
          aspect-ratio="4/3"
          >
          
          <v-carousel-item
            v-for="n in 9"
            :key="n"
            reverse-transition="fade-transition"
            transition="fade-transition"
            :src="'img/dsd/'+n+'.webp'"
          >
         
          </v-carousel-item>
      
     
        </v-carousel>
        </v-card-text>
       </v-responsive>
       <v-card-actions>
         <v-btn color="grey darken-1"  text @click="showphoto = false">Закрыть</v-btn>
       </v-card-actions>
      </v-card>
    
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    overlay: false,
    showphoto: false
  })
};
</script>