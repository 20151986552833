var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"create"}},[_c('v-speed-dial',{attrs:{"bottom":"","right":"","absolute":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"indigo darken-3","dark":"","fab":"","large":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v("mdi-comment-text-multiple-outline")]):_c('v-icon',[_vm._v("mdi-comment-text-multiple")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"blue","href":"mailto:info@frydiving.ru"}},on),[_c('v-icon',[_vm._v("mdi-email")])],1)]}}])},[_c('span',[_vm._v("Написать письмо")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"green","href":"http://api.whatsapp.com/send?phone=79776225155"}},on),[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)]}}])},[_c('span',[_vm._v("Написать в WhatsApp")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"red darken-4"}},on),[_c('Callback',{attrs:{"textvisible":"no"}})],1)]}}])},[_c('span',[_vm._v("Заказать звонок")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"indigo darken-2","href":"tel:+79776225155"}},on),[_c('v-icon',[_vm._v("mdi-phone")])],1)]}}])},[_c('span',[_vm._v("Позвонить нам")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }