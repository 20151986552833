<template>
  <v-container>
    <v-row class="d-flex justify-center white--text text--darken-4">
      <v-col class="text-center display-1">
        <h2 class="Amatic my-0">Сколько стоит</h2>
        <v-icon class="my-0 white--text">mdi-wave</v-icon>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="12" sm="4" class>
            <v-card class="pa-2 text-center">
              <v-btn fab color="indigo" small class="my-4">
                <v-icon color="white">mdi-account-outline</v-icon>
              </v-btn>
              <p class="title font-weight-light text-uppercase">Для одного</p>
              <p>
                <span class="display-1 font-weight-light indigo--text mr-1">3000</span>
                <span>руб.</span>
              </p>

              <!-- v-card-actions>
                <v-btn color="indigo" block outlined>Записаться</v-btn>
              </v-card-actions -->
            </v-card>
          </v-col>

          <v-col cols="12" sm="4" class>
            <v-card class="pa-2 text-center">
              <v-btn fab color="indigo" small class="my-4">
                <v-icon color="white">mdi-account-outline</v-icon>
              </v-btn>
              <v-btn fab color="indigo" small class="my-4">
                <v-icon color="white">mdi-account-outline</v-icon>
              </v-btn>

              <p class="title font-weight-light text-uppercase">Для двоих</p>
              <p>
                <span class="display-1 font-weight-light indigo--text mr-1">5500</span>
                <span>руб.</span>
              </p>
              <!-- v-card-actions>
                <v-btn color="indigo" block outlined>Записаться</v-btn>
              </v-card-actions -->
            </v-card>
          </v-col>

          <v-col cols="12" sm="4" class>
            <v-card class="pa-2 text-center">
              <v-btn fab color="indigo" small class="my-4">
                <v-icon color="white">mdi-account-outline</v-icon>
              </v-btn>

              <v-btn fab color="indigo" small class="my-4">
                <v-icon color="white">mdi-account-outline</v-icon>
              </v-btn>

              <v-btn fab color="indigo" small class="my-4">
                <v-icon color="white">mdi-account-outline</v-icon>
              </v-btn>

              <p class="title font-weight-light text-uppercase">Для троих</p>
              <p>
                <span class="display-1 font-weight-light indigo--text mr-1">7000</span>
                <span>руб.</span>
              </p>
              <!-- v-card-actions>
                <v-btn color="indigo" block outlined>Записаться</v-btn>
              </v-card-actions -->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="col-12 col-md-8 col-sm-10">
      <v-btn 
        block 
        x-large 
        color="red" 
        dark 
        @click="$vuetify.goTo('#ContactForm')">
          Записаться
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>    
                       
<script>
export default {
  name: "Cost",
  data: () => ({
    items: [
      {
        name: "Олег Минаев",
        number: "Инструктор-тренер SNSI #24951",
        src: "img/instructors/minaev.webp"
      },
      {
        name: "Алексей Кашкаров",
        number: "Инструктор SNSI #24950",
        src: "img/instructors/kashkarov.webp"
      },
      {
        name: "Михаил Гаврилов",
        number: "Инструктор SNSI #24953",
        src: "img/instructors/gavrilov.webp"
      },
      {
        name: "Юлия Лазарева",
        number: "Инструктор SNSI #24978",
        src: "img/instructors/lazareva.webp"
      },
      {
        name: "Екатерина Яркина",
        number: "Инструктор SNSI #24722",
        src: "img/instructors/yarkina.webp"
      },
      {
        name: "Ирина Северинова",
        number: "Дайвмастер SNSI #25129",
        src: "img/instructors/severinova.webp"
      }
    ]
  })
};
</script>
<style scoped>
.downstr {
  line-height: 2rem !important;
}
</style>