const state = {
    snackmessage: false
};
 
const getters = {
    // Геттеры
};
 
const mutations = {
    SNACK_CHANGE: (state, snackmessage) => {state.snackmessage = snackmessage},
};
 
const actions = {
    snack_off (context) {
        context.commit('SNACK_CHANGE', false)
    },
    snack_on (context) {
        context.commit('SNACK_CHANGE', true)
    }
};
 
// Экспортируем наружу
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}