<template>
  <v-row class="d-flex justify-center">
    <v-col class="col-4 d-flex justify-center" v-for="(item, i) in items" :key="i">
      <div class="text-center">
        <v-avatar size="110px" class="mb-3">
          <img :src="item.src" />
        </v-avatar>
        <p class="my-0 indigo--text text--darken-4 Amatic Amatic-16 downstr">{{ item.name }}</p>
        <p class="my-0">{{ item.number }}</p>
      </div>
    </v-col>
  </v-row>
</template>    
                       
<script>
export default {
  name: "Instructors",
  data: () => ({
    items: [
      {
        name: "Олег Минаев",
        number: "Инструктор-тренер SNSI #24951",
        src: "img/instructors/minaev.webp"
      },
      {
        name: "Алексей Кашкаров",
        number: "Инструктор SNSI #24950",
        src: "img/instructors/kashkarov.webp"
      },
      {
        name: "Михаил Гаврилов",
        number: "Инструктор SNSI #24953",
        src: "img/instructors/gavrilov.webp"
      },
      {
        name: "Юлия Лазарева",
        number: "Инструктор SNSI #24978",
        src: "img/instructors/lazareva.webp"
      },
      {
        name: "Екатерина Яркина",
        number: "Инструктор SNSI #24722",
        src: "img/instructors/yarkina.webp"
      },
      {
        name: "Ирина Северинова",
        number: "Дайвмастер SNSI #25129",
        src: "img/instructors/severinova.webp"
      },
    ]
  })
};
</script>
<style scoped>
.downstr {
  line-height: 2rem !important;
}
</style>