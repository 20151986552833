<template>
  <v-card id="create">
    <v-speed-dial v-model="fab" bottom right absolute fixed>
      <template v-slot:activator>
        <v-btn v-model="fab" color="indigo darken-3" dark fab large>
          <v-icon v-if="fab">mdi-comment-text-multiple-outline</v-icon>
          <v-icon v-else>mdi-comment-text-multiple</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark small color="blue" href="mailto:info@frydiving.ru">
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </template>
        <span>Написать письмо</span>
      </v-tooltip>



      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            dark
            small
            color="green"
            href="http://api.whatsapp.com/send?phone=79776225155"
          >
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
        </template>
        <span>Написать в WhatsApp</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            dark
            small
            color="red darken-4"
          >
            <Callback textvisible = 'no' />
          </v-btn>
        </template>
        <span>Заказать звонок</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab dark small color="indigo darken-2" href="tel:+79776225155">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </template>
        <span>Позвонить нам</span>
      </v-tooltip>
      
    </v-speed-dial>
  </v-card>
</template>


<script>
import Callback from "@/components/Callback";
export default {
  name: "Speeddial",
   components: {
     Callback
   },
  data: () => ({
    fab: false
  }),

  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    }
  }
};
</script>


