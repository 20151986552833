<template>
  <v-app>
    <div>
      <v-card class="d-none d-md-flex">
        <v-app-bar src color="indigo" class fixed extended dark dense>
          <v-toolbar-title>TryDiving.ru</v-toolbar-title>
          <v-spacer></v-spacer>

          <Callback textvisible="yes" />

          <template v-slot:extension>
            <v-tabs
              v-model="selectedTab"
              background-color="indigo"
              flat
              class="AmaticTab"
            >
              <v-tab class="AmaticTab" @click="$vuetify.goTo('#Up')"
                >Наверх</v-tab
              >
              <v-tab class="AmaticTab" @click="$vuetify.goTo('#How')"
                >Как</v-tab
              >
              <v-tab class="AmaticTab" @click="$vuetify.goTo('#Instructors')"
                >С кем</v-tab
              >
              <v-tab class="AmaticTab" @click="$vuetify.goTo('#Cost')"
                >Стоимость</v-tab
              >
              <v-tab class="AmaticTab" @click="$vuetify.goTo('#FAQ')"
                >Вопрос-ответ</v-tab
              >
              <v-tab class="AmaticTab" @click="$vuetify.goTo('#Contacts')"
                >Контакты</v-tab
              >
              <v-tab class="AmaticTab" @click="$vuetify.goTo('#After')"
                >Что потом?</v-tab
              >
              <v-tab class="AmaticTab" @click="$vuetify.goTo('#ContactForm')"
                >Записаться</v-tab
              >
            </v-tabs>
          </template>
        </v-app-bar>
      </v-card>

      <v-card class="d-sm-flex d-md-none">
        <v-app-bar src color="indigo" app fixed dark>
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-toolbar-title class="pl-0">TryDiving.ru</v-toolbar-title>
          <v-spacer></v-spacer>
          <Callback textvisible="yes" />
        </v-app-bar>
      </v-card>
    </div>

    <v-navigation-drawer
      hide-overlay
      v-model="drawer"
      temporary
      app
      width="auto"
      dark
      color="indigo darken-4"
    >
      <v-list shaped nav>
        <v-subheader>ПОПРОБУЙ ДАЙВИНГ!</v-subheader>
        <v-list-item-group color="indigo" v-model="selectedTab">
          <v-list-item
            @click="
              drawer = !drawer
              $vuetify.goTo('#Up')
            "
          >
            <v-list-item-icon>
              <v-icon color="indigo accent-1">mdi-arrow-up-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Наверх</v-list-item-title>
              <v-list-item-subtitle>В начало страницы</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              drawer = !drawer
              $vuetify.goTo('#How')
            "
          >
            <v-list-item-icon>
              <v-icon color="indigo accent-1">mdi-diving-scuba-flag</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Как</v-list-item-title>
              <v-list-item-subtitle
                >Как проходит погружение</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              drawer = !drawer
              $vuetify.goTo('#Instructors')
            "
          >
            <v-list-item-icon>
              <v-icon color="indigo accent-1">mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>С кем</v-list-item-title>
              <v-list-item-subtitle>Наши инструкторы</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              drawer = !drawer
              $vuetify.goTo('#Cost')
            "
          >
            <v-list-item-icon>
              <v-icon color="indigo accent-1">mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Сколько стоит</v-list-item-title>
              <v-list-item-subtitle>Стоимость погружения</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              drawer = !drawer
              $vuetify.goTo('#FAQ')
            "
          >
            <v-list-item-icon>
              <v-icon color="indigo accent-1"
                >mdi-frequently-asked-questions</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Вопрос-ответ</v-list-item-title>
              <v-list-item-subtitle
                >Часто задаваемые вопросы</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              drawer = !drawer
              $vuetify.goTo('#Contacts')
            "
          >
            <v-list-item-icon>
              <v-icon color="indigo accent-1">mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Контакты</v-list-item-title>
              <v-list-item-subtitle
                >Адрес, телефон, e-mail</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              drawer = !drawer
              $vuetify.goTo('#After')
            "
          >
            <v-list-item-icon>
              <v-icon color="indigo accent-1">mdi-head-question-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Что потом?</v-list-item-title>
              <v-list-item-subtitle
                >После пробного погружения</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              drawer = !drawer
              $vuetify.goTo('#ContactForm')
            "
          >
            <v-list-item-icon>
              <v-icon color="indigo accent-1">mdi-form-select</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Записаться</v-list-item-title>
              <v-list-item-subtitle>Форма онлайн-заявки</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <section
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0.5],
          },
        }"
        class="indigo darken-4"
        id="Up"
      >
        <v-container fluid>
          <v-row class="px-48 d-none d-md-flex indigo darken-4"></v-row>


          <v-row class="d-flex justify-center white--text">
            <v-col class="text-center display-2">
              <h1 class="Amatic upstr2">Попробуй дайвинг!</h1>
              <v-icon color="white" class="my-0">mdi-wave</v-icon>
              <p class="Amatic mt-0 mb-0">
                Начни подводное приключение прямо сейчас
              </p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-stretch">
            <v-col class="col-12 col-md-8">
              <v-carousel hide-delimiters>
                <v-carousel-item
                  v-for="n in 5"
                  :key="n"
                  :src="'img/carousel/' + n + '.webp'"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col class="col-12 col-md-4 d-flex align-stretch">
              <v-container class="indigo d-flex align-stretch">
                <v-col class="col-12 d-flex flex-column justify-content">
                  <div class="font-weight-light headline white--text">
                    <p class="upstr text-center text-md-left">
                      Устали от однообразия?
                    </p>
                    <p class="upstr text-center text-md-left">
                      Хотите изменить свою жизнь?
                    </p>
                    <p class="upstr text-center text-md-left">
                      Приходите на пробное погружение с аквалангом!
                    </p>
                    <p class="upstr text-center text-md-left">
                      Добавьте к своему миру еще один - подводный!
                    </p>
                  </div>

                  <div class="mt-auto">
                    <v-btn
                      block
                      x-large
                      color="red"
                      dark
                      @click="$vuetify.goTo('#ContactForm')"
                      >Записаться</v-btn
                    >
                  </div>
                </v-col>

                <v-row>
                  <v-col></v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0.4],
          },
        }"
        id="How"
      >
        <v-container>
          <v-row class="d-flex justify-center indigo--text">
            <v-col class="text-center display-1">
              <h2 class="Amatic">Как проходит погружение</h2>
              <v-icon class="my-0">mdi-wave</v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col class="col-12 col-sm-5">
              <p class="title">На суше</p>
              <ul>
                <li>Познакомитесь с вашим инструктором</li>
                <li>Послушаете брифинг и обсудите предстоящее погружение</li>
                <li>Зададите волнующие вас вопросы</li>
                <li>Заполните необходимые бумаги</li>
                <li>
                  Самостоятельно соберете комплект акваланга под руководством
                  инструктора
                </li>
              </ul>

              <p class="title mt-4">В воде</p>
              <ul>
                <li>Попробуете дышать под водой на мелкой глубине</li>
                <li>Перейдете в глубокую часть бассейна</li>
                <li>Ощутите невесомость и ощущение свободы на глубине</li>
                <li>Инструктор будет рядом и позаботится о вас!</li>
              </ul>
            </v-col>
          </v-row>
          <HowPhoto />
        </v-container>
      </section>

      <section
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0.5],
          },
        }"
        class="indigo lighten-5"
        id="Instructors"
      >
        <v-container>
          <v-row class="d-flex justify-center indigo--text text--darken-4">
            <v-col class="text-center display-1">
              <h2 class="Amatic my-0">Кто проводит</h2>
              <p class="Amatic my-0">наши инструкторы</p>
              <v-icon class="my-0">mdi-wave</v-icon>
            </v-col>
          </v-row>
          <Instructors />
        </v-container>
      </section>

      <section
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0.5],
          },
        }"
        class="indigo darken-4"
        id="Cost"
      >
        <Cost />
      </section>

      <section
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0.5],
          },
        }"
        class="white"
        id="FAQ"
      >
        <Faq />
      </section>

      <section
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0.5],
          },
        }"
        class="indigo white--text"
        id="Contacts"
      >
        <v-container dark>
          <v-row class="d-flex justify-center">
            <v-col class="text-center display-1">
              <h2 class="Amatic">Где проводится погружение</h2>
              <v-icon color="white" class="my-0">mdi-wave</v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-stretch">
            <v-col class="col-12 col-md-6">
              <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Aab2e6766d67f35d491574eb7013a073d8f8f8197d614c78f694ac9b3a3da2199&amp;source=constructor"
                width="100%"
                height="100%"
                frameborder="0"
              ></iframe>
            </v-col>
            <v-col class="col-12 col-md-6 font-weight-light">
              <p class="title font-weight-light">Дайвклуб AquaWorld Diving</p>
              <p>
                <span
                  >Мы проводим пробные погружения и обучение дайвингу по
                  адресу</span
                >
                <br />
                <span class="font-weight-regular"
                  >г. Москва, ул. 1-я Владимирская 10б, стр. 2, бассейн
                  "Луч"</span
                >
                <br />
                <span class="subtitle-2 font-weight-light"
                  >м. Шоссе Энтузиастов или м. Перово</span
                >
              </p>
              <p>
                <span>Тел.: +7 (977) 622-51-55 (телефон, WhatsApp)</span>
                <br />
                <span>E-mail: info@awdiving.ru</span>
                <br />
                <span>Сайт клуба: awdiving.ru</span>
                <br />
              </p>
              <p>
                В летний период пробные погружения возможны в открытых водоемах
                на выездах выходного дня с клубом.
              </p>
              <v-btn target="_blank" outlined dark href="https://awdiving.ru">
                На сайт клуба
                <v-icon class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0.9],
          },
        }"
        class="white"
        id="After"
      >
        <v-container>
          <v-row class="d-flex justify-center indigo--text text--darken-4">
            <v-col class="text-center display-1">
              <h2 class="Amatic my-0">А что потом?</h2>
              <v-icon class="my-0">mdi-wave</v-icon>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="col-8 text-center title font-weight-light">
              <p>Вы получите удовольствие и расслабитесь.</p>
              <p>Снимете напряжение после рабочих будней.</p>
              <p>
                Расскажете друзьям и знакомым о вашем приключении, поделитесь
                впечатлениями и фото.
              </p>
              <p>
                Поймете, как работает снаряжение для дайвинга и и как собирать
                комплект акваланга.
              </p>
              <p>
                Запишетесь со скидкой на обучение дайвингу по курсу
                <a
                  target="_blank"
                  href="https://awdiving.ru/courses/dsd-owd/owd.html"
                  >Open Water Diver</a
                >
                или повторное погружение со скидкой.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section
        v-intersect="{
          handler: onIntersect,
          options: {
            threshold: [0.5],
          },
        }"
        class="indigo darken-4"
        id="ContactForm"
      >
        <v-container>
          <v-row class="d-flex justify-center white--text text--darken-4">
            <v-col class="text-center display-1">
              <h2 class="Amatic my-0">Записаться</h2>
              <v-icon class="my-0 white--text">mdi-wave</v-icon>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="col-12 col-md-8">
              <v-card class="text-center pa-4">
                <Form />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-content>

    <CookieLaw />
    <Speeddial />

    <v-footer>
      <v-row class="d-flex justify-center">
        <v-col class="text-center">
          <p class="title font-weight-light">Дайвклуб AquaWorld Diving</p>
          <p>
            <span class="font-weight-regular"
              >г. Москва, ул. 1-я Владимирская 10б, стр. 2, бассейн "Луч"</span
            >
            <br />
            <span class="subtitle-2 font-weight-light"
              >м. Шоссе Энтузиастов или м. Перово</span
            >
          </p>
          <p>
            <span>Тел.: +7 (977) 622-51-55 (телефон, WhatsApp)</span>
            <br />
            <span>
              E-mail:
              <a href="mailto:info@awdiving.ru">info@awdiving.ru</a>
            </span>
            <br />
            <span>
              Сайт клуба
              <a target="_blank" href="https://awdiving.ru">awdiving.ru</a>
            </span>
            <br />
          </p>

          <!--          <p>
            <v-btn
              fab
              dark
              small
              elevation="0"
              color="indigo"
              href="https://www.facebook.com/groups/awdiving"
              target="_blank"
              class="mr-2"
            >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              elevation="0"
              color="indigo"
              href="https://www.instagram.com/aquaworlddiving/"
              target="_blank"
              class="mr-2"
            >
              <v-icon>mdi-instagram</v-icon>
            </v-btn>

            <v-btn
              fab
              dark
              small
              elevation="0"
              color="indigo"
              href="https://www.youtube.com/channel/UCN8grc46cMwRSk_vlpL0j5A"
              target="_blank"
              class="mr-2"
            >
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
          </p> -->

          <Soglashenie />
          <p class="body-2">
            Автор фото на заставке
            <br />Роман Фомин
            <br />
            <a target="_blank" href="https://divemasta.ru/">divemasta.ru</a>
          </p>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  import Form from '@/components/Form'
  import Instructors from '@/components/Instructors'
  import Faq from '@/components/Faq'
  import HowPhoto from '@/components/HowPhoto'
  import CookieLaw from '@/components/CookieLaw'
  import Soglashenie from '@/components/Soglashenie'
  import Speeddial from '@/components/Speeddial'
  import Cost from '@/components/Cost'
  import Callback from '@/components/Callback'
  import Carousel from '@/components/Carousel'

  export default {
    name: 'App',

    options: {
      threshold: 1.0,
      rootMargin: '300px',
    },

    components: {
      Form,
      Instructors,
      Faq,
      HowPhoto,
      CookieLaw,
      Soglashenie,
      Speeddial,
      Cost,
      Callback,
      Carousel,
    },

    data: () => ({
      isIntersecting: false,
      selectedTab: 0,
      drawer: false,
    }),

    methods: {
      onIntersect: function(entries) {
        // onIntersect: function(entries, observer, isIntersecting) {
        // More information about these options
        // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        // this.isIntersecting = entries[0].isIntersecting;

        this.isIntersecting = entries[0].isIntersecting
        if (entries[0].isIntersecting) {
          entries.forEach((entry) => {
            var s = entry.target.id
            if (s === 'Up') {
              this.selectedTab = 0
            }
            if (s === 'How') {
              this.selectedTab = 1
            }
            if (s === 'Instructors') {
              this.selectedTab = 2
            }
            if (s === 'Cost') {
              this.selectedTab = 3
            }
            if (s === 'FAQ') {
              this.selectedTab = 4
            }
            if (s === 'Contacts') {
              this.selectedTab = 5
            }
            if (s === 'After') {
              this.selectedTab = 6
            }
            if (s === 'ContactForm') {
              this.selectedTab = 7
            }
          })
        }
      },
    },
  }
</script>
<style>
  @import url('https://fonts.googleapis.com/css?family=Amatic+SC|Neucha|Pacifico&display=swap');

  .Pacifico {
    font-family: 'Pacifico', cursive;
    font-weight: 100 !important;
  }
  .Amatic {
    font-family: 'Amatic SC', cursive !important;
    font-weight: normal !important;
  }

  .Amatic-16 {
    font-size: 2rem !important;
  }

  .AmaticTab {
    font-family: 'Amatic SC', cursive;
    font-size: 1.4rem !important;
    font-weight: bold !important;
  }

  a.AmaticTab {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6) !important;
  }

  a.AmaticTab.router-link-exact-active.router-link-active {
    color: white !important;
  }

  .Neucha {
    font-family: 'Neucha', cursive;
  }
  .fon-bottom {
    background: url(img/fon-bottom.webp) no-repeat;
    background-size: cover;
    background-position: center top;
  }

  .px-48 {
    height: 48px;
  }

  .upstr {
    line-height: 2.4rem !important;
  }

  .upstr2 {
    line-height: 4rem !important;
  }
</style>
