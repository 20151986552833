<template>
  <v-container>
    <v-row class="d-flex justify-center indigo--text text--darken-4">
      <v-col class="text-center display-1">
        <h2 class="Amatic my-0">Вопрос-ответ</h2>
        <v-icon class="my-0 indigo--text">mdi-wave</v-icon>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col class="col-12 col-md-8 col-sm-10">
        <v-expansion-panels flat popout focusable hover>
          <v-expansion-panel class="">
            <v-expansion-panel-header class="">Сколько времени занимает погружение?</v-expansion-panel-header>
            <v-expansion-panel-content>
              Около 3 часов, поэтому пробное погружение можно запланировать на любой вечер
              в течение недели. Занятие включает в себя подробный рассказ о дайвинге,
              что происходит под водой с нашим организмом, какое снаряжение мы используем,
              как его подготовить и, конечно, самая интересная часть программы – погружение!
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Что брать с собой в бассейн?</v-expansion-panel-header>
            <v-expansion-panel-content>
              Вам будет нужен стандартный набоор того, что вы обычно берете в бассейн:
              <ul>
                <li>Тапочки для бассейна</li>
                <li>Купальник (плавки)</li>
                <li>Шапочка/бандана (найдем, дадим, если нет)</li>
                <li>Полотенце</li>
              </ul>Фен есть в раздевалках бассейна, свой брать не обязательно.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Я не умею плавать или плохо плаваю — можно ли погружаться с аквалангом?</v-expansion-panel-header>
            <v-expansion-panel-content>Для пробного погружения с аквалангом навык обычного плавания не требуется. Ваш инструктор объяснит, как передвигаться под водой.</v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Я ношу линзы. Можно ли в них нырять?</v-expansion-panel-header>
            <v-expansion-panel-content>Да, конечно можно. Очень многие дайверы ныряют в линзах без проблем.</v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Есть ли возрастные ограничения для занятия дайвингом?</v-expansion-panel-header>
            <v-expansion-panel-content>
              Да, на момент погружения вам должно быть не менее 10 лет.
              Для детей и подростков от 10 до 18 лет потребуется присутствие родителей
              (опекунов), или заранее заполненное согласие по установленной форме.
              Верхнего ограничения по возрасту в дайвинге нет,
              один из гуру дайвинга перестал нырять только к 82 годам.
              Все зависит от желания и здоровья.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Есть ли медицинские противопоказания?</v-expansion-panel-header>
            <v-expansion-panel-content>
              Если вы здоровы, в хорошей физической форме, не простужены, не имеете заболеваний сердечно-сосудистой системы,
              проблем с сосудами и давлением, то, скорее всего, вам можно заниматься дайвингом.
              Перед занятием вы должны будете заполнить
              <a
                target="_blank"
                href="https://awdiving.ru/courses/medanketa.html"
              >медицинскую анкету</a>.
              В ней указаны заболевания, при наличии которых вам потребуется
              справка от врача. Если же вы можете ответить «нет» на все пункты этой анкеты – вам справка не нужна.
              Мы доверяем вам, чтобы вы доверяли нам.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Это опасно?</v-expansion-panel-header>
            <v-expansion-panel-content>Нет. При соблюдении правил, о которых вам расскажет инструктор, дайвинг абсолютно безопасен!</v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>А что потом, после пробного погружения?</v-expansion-panel-header>
            <v-expansion-panel-content>
              Если после пробного погружения вы решите обучиться дайвингу,
              вы можете записаться на
              <a
                target="_blank"
                href="https://awdiving.ru/courses/dsd-owd/owd.html"
              >начальный курс обучения дайвингу SNSI Open Water Diver</a>
              со скидкой 2500 рублей или записаться на повторное погружение с инструктором
              по цене 1500 руб.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
     
      </v-col>
    </v-row>

  </v-container>
</template>    
                       
<script>
export default {
  name: "Faq",
  data: () => ({
    items: []
  })
};
</script>