<template>
  <div>
    <v-row class="d-flex align-stretch">
      <v-col class="col-12">
        <v-carousel hide-delimiters>
          <v-carousel-item
            v-for="n in 5"
            :key="n"
            :src="'img/carousel/' + n + '.webp'"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
      absolute: true,
      overlay: false,
    }),
  }
</script>
