import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import snackmessages from './modules/snackmessages';
export default new Vuex.Store({
  modules: {
    snackmessages
  }
});
