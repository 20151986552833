<template>
  <div class="text-center">
    <v-dialog v-model="callback" width="500">
      <template v-slot:activator="{ on }">

        <v-btn v-if="textvisible==='yes'" 
          small 
          dark 
          color="red"
           
          rounded 
          v-on="on"
        >
          <v-icon class="body-2 mr-2">mdi-phone-incoming</v-icon>
          <span >Заказать звонок</span>
        </v-btn>

        <v-icon v-on="on" v-if="textvisible==='no'">mdi-phone-incoming</v-icon>

      </template>

      <v-card>
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
          @submit.prevent="onSubmit"
          id="CallbackForm"
        >
          <v-card-title class="indigo AmaticTab Amatic-16 mb-6 white--text">
            Заказать звонок
            <v-spacer></v-spacer>
            <v-icon @click="callback = false" color="indigo lighten-3" background="transparent" >mdi-close</v-icon>
            </v-card-title>

          <v-card-text class="pb-0">
            <v-text-field
              dense
              background-color="white"
              v-model="name"
              :rules="nameRules"
              label="Ваше имя"
              required
              outlined
              clearable
              hint="Например Иван Иванов"
              name="name"
              prepend-inner-icon="mdi-account-box"
            ></v-text-field>
            <v-text-field
              dense
              background-color="white"
              v-model="phone"
              :rules="phoneRules"
              label="Телефон"
              :counter="12"
              required
              outlined
              clearable
              hint="Например +79035122357"
              name="phone"
              type="tel"
              prepend-inner-icon="mdi-phone"
              placeholder
              v-mask="'+7##########'"
            ></v-text-field>
            <v-checkbox
              dense
              background-color="transparent"
              class="mt-0"
              v-model="checkbox"
              :rules="[v => !!v || 'Вы должны согласиться, чтобы продолжить']"
              label="Соглашаюсь с Политикой конфиденциальности"
              required
            ></v-checkbox>
          </v-card-text>

          

          <v-card-actions>
            
        <v-btn color="grey darken-1"  text @click="callback = false">Закрыть</v-btn>

<v-spacer></v-spacer>

            <v-btn
              @click="callback = false"
              dense
              class="white--text align-right"
              :disabled="!valid"
              color="indigo"
              type="submit"
            >Отправить</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>



<script>
import axios from "axios";
import { mask } from "vue-the-mask";
export default {
  props: [
    'textvisible',
  ],
  directives: { mask },
  data: () => ({
    callback: false,
    valid: true,
    name: "",
    nameRules: [
      v => !!v || "Вы не заполнили имя!",
      v => (v && v.length >= 3) || "Минимум 3 символа"
    ],
    phone: "",
    phoneRules: [
      v => !!v || "Вы не заполнили номер!",
      v => (v && v.length >= 12) || "Номер содержит 11 цифр!"
    ],
    checkbox: false,
    lazy: false
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onSubmit() {
      let CallbackForm = document.getElementById("CallbackForm");
      var bodyFormData = new FormData(CallbackForm);

      bodyFormData.append("sitename", "TryDiving");
      bodyFormData.append("fromto", "info@snsipro.ru");
      bodyFormData.append("email", "Callback");
      bodyFormData.append("courses", "Звонок от вас");
      bodyFormData.append("message", "");
      

      axios({
        method: "post",
        url: "https://snsipro.ru/api/callback",
        data: bodyFormData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            this.$refs.form.reset();
            this.$store.dispatch("snackmessages/snack_on");
            setTimeout(() => {
              this.$store.dispatch("snackmessages/snack_off");
            }, 3000);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

};
</script>
